import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ProfileUserInfo } from '../../components/profile/ProfileUserInfo';
import { PointHistoryTables } from '../../components/profile/PointHistoryTables';
import classNames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  CircularProgress,
  Divider,
  Button
} from '@nextui-org/react';
import { useUser } from '../../hooks/use-user.js';

export const Profile = () => {
  const { rsn } = useParams();
  const {
    account,
    setAccount,
    member,
    setMember,
    pointHistories,
    setPointHistories,
    leaderboardRank,
    setLeaderboardRank
  } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(null);

  const generateHsUrl = useCallback(
    (rsn) => {
      switch (account.accountType) {
        case 'Ironman':
          return `https://secure.runescape.com/m=hiscore_oldschool_ironman/hiscorepersonal?user1=${rsn}`;
        case 'Ultimate Ironman':
          return `https://secure.runescape.com/m=hiscore_oldschool_ultimate/hiscorepersonal?user1=${rsn}`;
        case 'Hardcore Ironman':
          return `https://secure.runescape.com/m=hiscore_oldschool_hardcore_ironman/hiscorepersonal?user1=${rsn}`;
        default:
          return `https://secure.runescape.com/m=hiscore_oldschool/hiscorepersonal?user1=${rsn}`;
      }
    },
    [account]
  );

  useEffect(() => {
    if (rsn) {
      fetch(`/api/profile/${rsn}`)
        .then((res) => {
          if (res.status === 404) {
            setNotFound(true);
            return;
          } else if (res.status !== 200) {
            setError('Error fetching profile');
          } else {
            return res
              .json()
              .then(({ member, account, pointHistories, leaderboardRank }) => {
                setMember(member);
                setAccount(account);
                setPointHistories(pointHistories);
                setLeaderboardRank(leaderboardRank);
              });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [rsn]);

  const ProfileCard = ({ title, content, className }) => {
    return (
      <Card
        className={classNames(
          'font-extralight mt-2 w-full lg:w-1/4 border-1 border-divider',
          className
        )}
      >
        <CardHeader className="text-primary font-normal">{title}</CardHeader>
        <Divider />
        <CardBody>
          <div className="flex flex-col space-y-2">
            <span>{content}</span>
          </div>
        </CardBody>
      </Card>
    );
  };

  const profileContent = () => {
    return (
      <div>
        <div className="flex flex-row justify-between items-end">
          <ProfileUserInfo rsn={rsn} member={member} account={account} />
          <div>
            <Button className="flex-start mb-2 mr-2">
              <a
                href={`https://wiseoldman.net/players/${rsn}`}
                target="_blank"
                rel="noreferrer"
              >
                WOM
              </a>
            </Button>
            <Button className="flex-start mb-2 mr-2">
              <a href={generateHsUrl(rsn)} target="_blank" rel="noreferrer">
                Hiscores
              </a>
            </Button>
          </div>
        </div>
        <Divider />
        <div className="cards flex flex-col lg:flex-row">
          <ProfileCard
            title="Leaderboard Rank"
            content={`${leaderboardRank.position} / ${leaderboardRank.total}`}
            className="lg:mr-2"
          />
          <ProfileCard
            title="Embargo Score"
            content={(
              account.accountPoints + member.communityPoints
            ).toLocaleString()}
            className="lg:mr-2"
          />
          <ProfileCard
            title="Account Points"
            content={account.accountPoints.toLocaleString()}
            className="lg:mr-2"
          />
          <ProfileCard
            title="Community Points"
            content={member.communityPoints.toLocaleString()}
          />
        </div>
        <div className="flex flex-col 2xl:flex-row">
          <PointHistoryTables
            pointHistories={pointHistories}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  };

  const loadingContent = () => {
    return (
      <div className="flex justify-center">
        <CircularProgress aria-label="Loading..." />
      </div>
    );
  };

  const message = (content) => {
    return (
      <div className="text italic mb-4 mt-10 ml-2 text-center">{content}</div>
    );
  };

  const loadContent = () => {
    if (error) {
      return message('Error fetching profile... Try again later.');
    }
    if (notFound) {
      return message('Profile not found... Check the username and try again.');
    }
    return isLoading ? loadingContent() : profileContent();
  };

  return (
    <div className="flex flex-column justify-center w-full p-2">
      <div className="w-full lg:w-2/3">{loadContent()}</div>
    </div>
  );
};

export default Profile;
